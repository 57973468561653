// imports
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'


export default function BackgroundDiv({ className, style, children }) {
    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "bg_1.jpg" }) {
                    childImageSharp {
                        fluid(quality:90, maxWidth:1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    )

    const imageData = data.desktop.childImageSharp.fluid

    return (
        <BackgroundImage
            tag="div"
            className={className}
            style={style}
            fluid={imageData}
        >
            {children}
        </BackgroundImage>
    )
}
